import { makeStyles } from '@material-ui/core';
import { StaticImage } from 'gatsby-plugin-image';
import MetroSans from '../fonts/metro-sans/metroSans';
import colors from '../styles/colors';
import { tinyText } from '../styles/dimensions';

interface Props {
	onClick: () => void;
	pageData: string;
}

const PageStatus = (props: Props) => {
	const { onClick, pageData } = props;

	const classes = useClasses();

	return (
		<div className={classes.container}>
			<StaticImage
				src="../images/contact/arrow_right_button.svg"
				alt="Go back"
				layout="constrained"
				loading="lazy"
				placeholder="tracedSVG"
				onClick={onClick}
				className={classes.arrow}
			/>
			<span className={classes.page}>{pageData}</span>
		</div>
	);
};

export default PageStatus;

const useClasses = makeStyles({
	container: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	arrow: {
		cursor: 'pointer',
	},
	page: {
		fontFamily: MetroSans.book,
		fontSize: tinyText.fontSize,
		lineHeight: tinyText.lineHeight,
		color: colors.textGrey,
		marginLeft: '1em',
	},
});
