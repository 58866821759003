export class ContactProject {
	public name: string;
	public email: string;
	public projectType: 'development' | 'design' | 'both' | 'other';
	public platform: 'mobile' | 'web';
	public budget?: string;
	public projectDescription?: string;

	constructor(
		name: string,
		email: string,
		projectType: 'development' | 'design' | 'both' | 'other',
		platform: 'mobile' | 'web',
		budget?: string,
		projectDescription?: string
	) {
		this.name = name;
		this.email = email;
		this.projectType = projectType;
		this.platform = platform;
		if (budget) this.budget = budget;
		if (projectDescription) this.projectDescription = projectDescription;
	}

	public toJSON() {
		return {
			name: this.name,
			email: this.email,
			projectType: this.projectType,
			platform: this.platform,
			budget: this.budget,
			projectDescription: this.projectDescription,
		};
	}

	public static fromForm(maybe: any): ContactProject {
		return new ContactProject(
			maybe.name,
			maybe.email,
			maybe.projectType,
			maybe.platform,
			maybe.budget,
			maybe.projectDescription
		);
	}
}
