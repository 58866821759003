import {
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	makeStyles,
	Radio,
	RadioGroup,
	TextField,
} from '@material-ui/core';
import { Fragment } from 'react';
import { Branch, Router } from '../../../pages/contact';
import content from '../../../content/contact/helpProject.json';
import MetroSans from '../../../fonts/metro-sans/metroSans';
import { defaultSectionWidth, mobileTinyText, regularText, smallText, tinyText } from '../../../styles/dimensions';
import colors from '../../../styles/colors';
import Rigraf from '../../../fonts/rigraf/regular/rigraf';
import RigrafExpanded from '../../../fonts/rigraf/expanded/rigrafExpanded';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import MainButton from '../../../elements/mainButton';
import { StaticImage } from 'gatsby-plugin-image';
import PageStatus from '../../../elements/pageStatus';
import ErrorStaticImage from '../../../elements/errorStaticImage';

interface Props {
	router: Router;
	loading: boolean;
	control: Control<Record<string, any>>;
	errors: DeepMap<Record<string, any>, FieldError>;
	previousStep: () => void;
	handleProjectSubmit: (values) => void;
	clearErrors: () => void;
}

interface ClassProps {
	router: Router;
	loading: boolean;
}

const HelpProject = (props: Props) => {
	const { router, previousStep, handleProjectSubmit, control, errors, loading } = props;

	const classes = useClasses({ router, loading });

	return (
        <section className={classes.section}>
			<Grid container>
				<Grid item sm={2}></Grid>

				<Grid item xs={12} sm={8} data-scroll data-scroll-speed="2">
					<PageStatus onClick={previousStep} pageData={content.page} />
					<p className={classes.help}>{content.help}</p>

					<form onSubmit={handleProjectSubmit}>
						{content.helpDetails.map((details, key) => (
							<Fragment key={key}>
								<h2 className={classes.header}>{details.label}</h2>
								<FormControl component="fieldset" className={classes.radioGroup}>
									<Controller
										render={({ field }) => (
											<RadioGroup {...field} aria-label={details.ariaLabel}>
												{details.details.map((item, i) => (
													<FormControlLabel
														key={i}
														value={item.value}
														label={item.label}
														control={
															<Radio
																icon={
																	<>
																		<div className={classes.radioIcon}></div>
																		<StaticImage
																			src="../../../images/contact/cd.png"
																			alt=""
																			className={classes.hoverIcon}
																			layout="constrained"
																			loading="eager"
																			placeholder="blurred"
																			aria-hidden="true"
																		/>
																	</>
																}
																checkedIcon={
																	<StaticImage
																		src="../../../images/contact/cd.png"
																		alt=""
																		className={classes.radioCheckedIcon}
																		layout="constrained"
																		loading="eager"
																		placeholder="blurred"
																		aria-hidden="true"
																	/>
																}
															/>
														}
														className={classes.radio}
													/>
												))}
											</RadioGroup>
										)}
										name={details.name}
										control={control}
										defaultValue=""
										rules={{ required: details.fieldError }}
									/>
									{errors[details.name] && (
										<FormHelperText classes={{ root: classes.fieldErrorContainer }}>
											<ErrorStaticImage />
											<span className={classes.fieldErrorSpan}>
												{errors[details.name]?.message}
											</span>
										</FormHelperText>
									)}
								</FormControl>
							</Fragment>
						))}

						<h2 className={classes.header}>{content.budget.label}</h2>
						<Controller
							render={({ field }) => (
								<TextField
									{...field}
									fullWidth
									placeholder={content.budget.placeholder}
									type={content.budget.type}
									classes={{ root: classes.inputFormControl }}
									InputProps={{
										classes: {
											underline: classes.inputUnderline,
											input: classes.input,
										},
									}}
								/>
							)}
							name={content.budget.name}
							control={control}
							defaultValue=""
						/>
						<h2 className={classes.header}>{content.project.label}</h2>
						<Controller
							render={({ field }) => (
								<TextField
									{...field}
									fullWidth
									placeholder={content.project.placeholder}
									type={content.project.type}
									classes={{ root: classes.inputFormControl }}
									InputProps={{
										classes: {
											underline: classes.inputUnderline,
											input: classes.input,
										},
									}}
								/>
							)}
							name={content.project.name}
							control={control}
							defaultValue=""
						/>

						<div>
							<MainButton type="submit" disabled={loading} text={content.submit} />
							<CircularProgress
								color="inherit"
								aria-busy="true"
								classes={{ root: classes.spinnerRoot }}
							/>
						</div>
					</form>
				</Grid>

				<Grid item sm={2}></Grid>
			</Grid>
		</section>
    );
};

export default HelpProject;

const useClasses = makeStyles({
	section: (props: ClassProps) => ({
		display: props.router.step === 2 && props.router.branch === Branch.project ? 'block' : 'none',
		paddingBottom: '7.625em',
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		'@media (max-width: 599px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
			paddingTop: '3.875em',
			paddingBottom: '5.25em',
		},
	}),
	help: {
		fontFamily: MetroSans.book,
		fontSize: tinyText.fontSize,
		lineHeight: tinyText.lineHeight,
		color: colors.textGrey,
		marginTop: '1.3571428571428572em',
		marginBottom: '0.7857142857142857em',
	},
	header: {
		marginTop: '0.275em',
		marginBottom: '0.55em',
		whiteSpace: 'pre-wrap',
		'@media (max-width: 599px)': {
			fontSize: regularText.fontSize,
			lineHeight: regularText.lineHeight,
			marginBottom: '0.9em',
		},
	},
	input: {
		width: 'calc(41.66666667% * 12 / 8)',
		fontFamily: MetroSans.book,
		fontSize: regularText.fontSize + ' !important',
		lineHeight: regularText.lineHeight + ' !important',
		color: colors.textBlack,
		'&::placeholder': {
			fontFamily: MetroSans.book,
		},
		'@media (max-width: 959px)': {
			width: '100%',
		},
		'@media (max-width: 599px)': {
			fontSize: smallText.fontSize + ' !important',
			lineHeight: smallText.lineHeight + ' !important',
		},
	},
	inputUnderline: {
		borderBottomColor: colors.textBlack,
		'&::after': {
			borderBottomColor: colors.textBlack,
		},
		'&.MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottomColor: colors.textBlack,
		},
	},
	inputFormControl: {
		marginBottom: '2.5em',
	},
	radio: {
		margin: '1em 0',
		'& span:first-of-type': {
			padding: 0,
		},
		'&:hover $hoverIcon': {
			opacity: 0.5,
		},
		'& span:last-of-type': {
			fontFamily: RigrafExpanded.semiBold,
			fontSize: regularText.fontSize,
			lineHeight: regularText.lineHeight,
			position: 'relative',
			left: 'calc(8.33% * 12 / 8)',
			'@media (max-width: 599px)': {
				fontFamily: Rigraf.semiBold,
				fontSize: smallText.fontSize,
				lineHeight: smallText.lineHeight,
			},
		},
	},
	radioIcon: {
		width: '69px',
		height: '69px',
		borderRadius: '50%',
		border: '1px solid black',
	},
	radioCheckedIcon: {
		width: '71px',
		height: '71px',
	},
	hoverIcon: {
		// @ts-ignore
		position: 'absolute !important',
		opacity: 0,
		'&:hover': {
			opacity: 0.8,
		},
	},
	radioGroup: {
		marginBottom: '2.25em',
	},
	spinnerRoot: (props: ClassProps) => ({
		display: props.loading ? 'inline-block' : 'none',
		position: 'relative',
		top: '18px',
		left: '30px',
		color: colors.textBlack,
	}),
	fieldErrorContainer: {
		position: 'absolute',
		bottom: 0,
		transform: 'translateY(120%)',
		whiteSpace: 'nowrap',
	},
	fieldErrorSpan: {
		color: colors.errorRed,
		fontFamily: MetroSans.regular,
		fontSize: mobileTinyText.fontSize,
		lineHeight: mobileTinyText.lineHeight,
		marginLeft: '0.6666666666666666em',
	},
});
