import { CircularProgress, FormControl, Grid, Input, makeStyles, TextField } from '@material-ui/core';
import MetroSans from '../../../fonts/metro-sans/metroSans';
import { Branch, Router } from '../../../pages/contact';
import colors from '../../../styles/colors';
import { defaultSectionWidth, mobileTinyText, regularText, smallText, tinyText } from '../../../styles/dimensions';
import content from '../../../content/contact/helpOther.json';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import MainButton from '../../../elements/mainButton';
import { StaticImage } from 'gatsby-plugin-image';
import ErrorStaticImage from '../../../elements/errorStaticImage';
import PageStatus from '../../../elements/pageStatus';

interface Props {
	router: Router;
	previousStep: () => void;
	handleOtherSubmit: (values) => void;
	control: Control<Record<string, any>>;
	loading: boolean;
	errors: DeepMap<Record<string, any>, FieldError>;
}

interface ClassProps {
	router: Router;
	loading: boolean;
}

const HelpOther = (props: Props) => {
	const { router, previousStep, handleOtherSubmit, control, loading, errors } = props;

	const classes = useClasses({ router, loading });

	return (
		<section className={classes.section}>
			<Grid container>
				<Grid item sm={2}></Grid>

				<Grid item xs={12} sm={8} data-scroll data-scroll-speed="2">
					<PageStatus onClick={previousStep} pageData={content.page} />
					<p className={classes.help}>{content.help}</p>

					<form onSubmit={handleOtherSubmit}>
						<div className={classes.fieldContainer}>
							<h2 className={classes.header}>{content.input.label}</h2>
							<Controller
								render={({ field, fieldState: { error } }) => (
									<TextField
										{...field}
										placeholder={content.input.placeholder}
										type={content.input.type}
										classes={{ root: classes.inputFormControl }}
										InputProps={{
											classes: {
												underline: classes.inputUnderline,
												input: classes.input,
											},
										}}
										error={Boolean(error)}
										helperText={
											error && (
												<>
													<ErrorStaticImage />
													<span className={classes.fieldErrorSpan}>{error?.message}</span>
												</>
											)
										}
										FormHelperTextProps={{ classes: { error: classes.fieldErrorContainer } }}
									/>
								)}
								name={content.input.name}
								control={control}
								defaultValue=""
								rules={{ required: content.input.fieldError }}
							/>
						</div>

						<div>
							<MainButton type="submit" disabled={loading} text={content.submit} />
							<CircularProgress
								color="inherit"
								aria-busy="true"
								classes={{ root: classes.spinnerRoot }}
							/>
						</div>
					</form>
				</Grid>

				<Grid item sm={2}></Grid>
			</Grid>
		</section>
	);
};

export default HelpOther;

const useClasses = makeStyles({
	section: (props: ClassProps) => ({
		display: props.router.step === 2 && props.router.branch === Branch.other ? 'block' : 'none',
		padding: '3.625em 0',
		paddingBottom: '7.625em',
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		'@media (max-width: 599px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
			paddingTop: '3.875em',
			paddingBottom: '5.25em',
		},
	}),
	help: {
		fontFamily: MetroSans.book,
		fontSize: tinyText.fontSize,
		lineHeight: tinyText.lineHeight,
		color: colors.textGrey,
		marginTop: '1.3571428571428572em',
		marginBottom: '0.7857142857142857em',
	},
	header: {
		marginTop: '0.275em',
		marginBottom: '0.55em',
		whiteSpace: 'pre-wrap',
		'@media (max-width: 599px)': {
			fontSize: regularText.fontSize,
			lineHeight: regularText.lineHeight,
			marginBottom: '0.9em',
		},
	},
	fieldContainer: {
		marginBottom: '1.5em',
	},
	input: {
		fontFamily: MetroSans.book,
		fontSize: regularText.fontSize + ' !important',
		lineHeight: regularText.lineHeight + ' !important',
		color: colors.textBlack,
		'&::placeholder': {
			fontFamily: MetroSans.book,
		},
		'@media (max-width: 599px)': {
			fontSize: smallText.fontSize + ' !important',
			lineHeight: smallText.lineHeight + ' !important',
		},
	},
	inputUnderline: {
		borderBottomColor: colors.textBlack,
		'&::after': {
			borderBottomColor: colors.textBlack,
		},
		'&.MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottomColor: colors.textBlack,
		},
	},
	inputFormControl: {
		marginBottom: '1em',
		width: 'calc(41.66666667% * 12 / 8)',
		'@media (max-width: 959px)': {
			width: '100%',
		},
	},
	spinnerRoot: (props: ClassProps) => ({
		display: props.loading ? 'inline-block' : 'none',
		position: 'relative',
		top: '18px',
		left: '30px',
		color: colors.textBlack,
	}),
	fieldErrorContainer: {
		position: 'absolute',
		bottom: 0,
		transform: 'translateY(120%)',
		whiteSpace: 'nowrap',
	},
	fieldErrorSpan: {
		color: colors.errorRed,
		fontFamily: MetroSans.regular,
		fontSize: mobileTinyText.fontSize,
		lineHeight: mobileTinyText.lineHeight,
		marginLeft: '0.6666666666666666em',
	},
});
