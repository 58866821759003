import { makeStyles, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import Layout from '../components/layout';
import About from '../components/sections/contact/about';
import HelpOther from '../components/sections/contact/helpOther';
import HelpProject from '../components/sections/contact/helpProject';
import IntroduceYourself from '../components/sections/contact/introduceYourself';
import TalkToUs from '../components/sections/contact/talkToUs';
import SEO from '../components/seo';
import MetroSans from '../fonts/metro-sans/metroSans';
import service from '../service/service';
import { smallText } from '../styles/dimensions';
import { ContactOther } from '../types/contactOther';
import { ContactProject } from '../types/contactProject';
import content from '../content/contact/page.json';
import Feedback from '../components/sections/contact/feedback';
import { descriptions, titles } from '../consts/seo';

export enum Branch {
	project = 'project',
	other = 'other',
}

export interface Router {
	step: number;
	branch: Branch | undefined;
}

const Contact = () => {
	const [loading, setLoading] = useState(false);
	const [router, setRouter] = useState<Router>({ step: 0, branch: undefined });
	const [snackbarOpen, setSnackbarOpen] = useState(false);

	const classes = useClasses();

	const previousStep = useCallback(() => {
		setRouter({ ...router, step: router.step - 1 });
	}, [router]);

	const nextStep = useCallback(() => {
		setRouter({ ...router, step: router.step + 1 });
	}, [router]);

	const setProjectBranch = useCallback(() => {
		setRouter({ step: router.step + 1, branch: Branch.project });
	}, [router]);

	const setOtherBranch = useCallback(() => {
		setRouter({ step: router.step + 1, branch: Branch.other });
	}, [router]);

	const {
		handleSubmit,
		control,
		formState: { errors },
		clearErrors,
		getValues,
		setError,
	} = useForm();

	const handleProjectSubmit = useCallback(
		(values) => {
			setLoading(true);
			service.contactProject(ContactProject.fromForm(values)).then((response) => {
				if (response === true) {
					nextStep();
				} else {
					setSnackbarOpen(true);
				}
				setLoading(false);
			});
		},
		[router]
	);

	const handleOtherSubmit = useCallback(
		(values) => {
			setLoading(true);
			service.contactOther(ContactOther.fromForm(values)).then((response) => {
				if (response === true) {
					nextStep();
				} else {
					setSnackbarOpen(true);
				}
				setLoading(false);
			});
		},
		[router]
	);

	const handleSnackbarClose = useCallback(() => {
		setSnackbarOpen(false);
	}, []);

	return (
		<Layout>
			<SEO title={titles.contact} description={descriptions.contact} />

			<About />
			<TalkToUs
				router={router}
				setProjectBranch={setProjectBranch}
				setOtherBranch={setOtherBranch}
				control={control}
				getValues={getValues}
			/>
			<IntroduceYourself
				router={router}
				previousStep={previousStep}
				nextStep={nextStep}
				control={control}
				errors={errors}
				setError={setError}
				clearErrors={clearErrors}
			/>
			{router.branch === Branch.project && (
				<HelpProject
					router={router}
					previousStep={previousStep}
					handleProjectSubmit={handleSubmit(handleProjectSubmit)}
					control={control}
					errors={errors}
					clearErrors={clearErrors}
					loading={loading}
				/>
			)}
			{router.branch === Branch.other && (
				<HelpOther
					router={router}
					previousStep={previousStep}
					handleOtherSubmit={handleSubmit(handleOtherSubmit)}
					control={control}
					loading={loading}
					errors={errors}
				/>
			)}
			<Feedback router={router} />
			<Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
				<Alert
					onClose={handleSnackbarClose}
					severity="error"
					variant="filled"
					classes={{ message: classes.snackbarAlert }}>
					{content.alertMessages.error}
				</Alert>
			</Snackbar>
		</Layout>
	);
};

export default Contact;

const useClasses = makeStyles({
	snackbarAlert: {
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
	},
});
