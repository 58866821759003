import { Grid, makeStyles, TextField } from '@material-ui/core';
import { useRef, useEffect, useCallback } from 'react';
import { Control, Controller, DeepMap, ErrorOption, FieldError } from 'react-hook-form';
import content from '../../../content/contact/introduceYourself.json';
import PageStatus from '../../../elements/pageStatus';
import MainButton from '../../../elements/mainButton';
import MetroSans from '../../../fonts/metro-sans/metroSans';
import { MAIL_PATTERN } from '../../../helpers/regex';
import { useScrollContext } from '../../../hooks/useScrollContext';
import { Router } from '../../../pages/contact';
import colors from '../../../styles/colors';
import { defaultSectionWidth, mobileTinyText, regularText, smallText, tinyText } from '../../../styles/dimensions';
import ErrorStaticImage from '../../../elements/errorStaticImage';

interface Props {
	router: Router;
	previousStep: () => void;
	nextStep: () => void;
	control: Control<Record<string, any>>;
	errors: DeepMap<Record<string, any>, FieldError>;
	setError: (name: string, error: ErrorOption) => void;
	clearErrors: (name?: string | string[]) => void;
}

interface ClassProps {
	router: Router;
}

const IntroduceYourself = (props: Props) => {
	const { control, router, previousStep, nextStep, errors, setError, clearErrors } = props;

	const formRef = useRef<any>(null);

	const classes = useClasses({ router });

	const scroll = useScrollContext();

	useEffect(() => {
		if (scroll) {
			setTimeout(() => {
				scroll.update();
			}, 1);
		}
	}, [scroll, router.step]);

	const handleNext = useCallback(() => {
		let error = false;
		if (formRef.current[content.name.name].value === '') {
			setError(content.name.name, { type: 'required', message: content.name.fieldError });
			error = true;
		}

		if (
			formRef.current[content.email.name].value === '' ||
			!MAIL_PATTERN.test(formRef.current[content.email.name].value)
		) {
			setError(content.email.name, { type: 'required', message: content.email.fieldError });
			error = true;
		}

		if (!error) {
			clearErrors();
			nextStep();
		}
	}, null);

	return (
		<section className={classes.section}>
			<Grid container>
				<Grid item sm={2}></Grid>

				<Grid item xs={12} sm={8} data-scroll data-scroll-speed="2">
					<PageStatus onClick={previousStep} pageData={content.page} />
					<p className={classes.introduceYourself}>{content.introduceYourself}</p>

					<form
						onSubmit={(event) => {
							event.preventDefault();
						}}
						ref={formRef}>
						<div className={classes.fieldContainer}>
							<h2 className={classes.header}>{content.name.label}</h2>
							<Controller
								render={({ field, fieldState: { error } }) => (
									<TextField
										{...field}
										onKeyDown={() => clearErrors(content.name.name)}
										placeholder={content.name.placeholder}
										type={content.name.type}
										classes={{ root: classes.inputFormControl }}
										InputProps={{
											classes: {
												underline: classes.inputUnderline,
												input: classes.input,
											},
										}}
										error={Boolean(error)}
										helperText={
											error && (
												<>
													<ErrorStaticImage />
													<span className={classes.fieldErrorSpan}>{error?.message}</span>
												</>
											)
										}
										FormHelperTextProps={{ classes: { error: classes.fieldErrorContainer } }}
									/>
								)}
								name={content.name.name}
								control={control}
								defaultValue=""
								rules={{ required: content.name.fieldError }}
							/>
						</div>

						<div className={classes.fieldContainer}>
							<h2 className={classes.header}>{content.email.label}</h2>
							<Controller
								render={({ field, fieldState: { error } }) => (
									<TextField
										{...field}
										onKeyDown={() => clearErrors(content.email.name)}
										placeholder={content.email.placeholder}
										type={content.email.type}
										classes={{ root: classes.inputFormControl }}
										InputProps={{
											classes: {
												underline: classes.inputUnderline,
												input: classes.input,
											},
										}}
										error={Boolean(error)}
										helperText={
											error && (
												<>
													<ErrorStaticImage />
													<span className={classes.fieldErrorSpan}>{error?.message}</span>
												</>
											)
										}
										FormHelperTextProps={{ classes: { error: classes.fieldErrorContainer } }}
									/>
								)}
								name={content.email.name}
								control={control}
								defaultValue=""
								rules={{
									required: content.email.fieldError,
									pattern: {
										value: MAIL_PATTERN,
										message: content.email.fieldError,
									},
								}}
							/>
						</div>

						<div>
							<MainButton type="submit" onClick={handleNext} text={content.continue} />
						</div>
					</form>
				</Grid>

				<Grid item sm={2}></Grid>
			</Grid>
		</section>
	);
};

export default IntroduceYourself;

const useClasses = makeStyles({
	section: (props: ClassProps) => ({
		display: props.router.step === 1 ? 'block' : 'none',
		padding: '3.75em 0',
		paddingBottom: '7.625em',
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		'@media (max-width: 599px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
			paddingTop: '3.875em',
			paddingBottom: '5.25em',
		},
	}),
	fieldContainer: {
		marginBottom: '1.5em',
	},
	input: {
		fontFamily: MetroSans.book,
		fontSize: regularText.fontSize + ' !important',
		lineHeight: regularText.lineHeight + ' !important',
		color: colors.textBlack,
		'&::placeholder': {
			fontFamily: MetroSans.book,
		},
		'@media (max-width: 599px)': {
			fontSize: smallText.fontSize + ' !important',
			lineHeight: smallText.lineHeight + ' !important',
		},
	},
	inputUnderline: {
		borderBottomColor: colors.textBlack,
		'&::after': {
			borderBottomColor: colors.textBlack,
		},
		'&.MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottomColor: colors.textBlack,
		},
	},
	inputFormControl: {
		marginBottom: '1em',
		width: 'calc(41.66666667% * 12 / 8)',
		'@media (max-width: 959px)': {
			width: '100%',
		},
	},
	introduceYourself: {
		fontFamily: MetroSans.book,
		fontSize: tinyText.fontSize,
		lineHeight: tinyText.lineHeight,
		color: colors.textGrey,
		marginTop: '1.3571428571428572em',
		marginBottom: '0.7857142857142857em',
	},
	header: {
		marginTop: '0.275em',
		marginBottom: '0.55em',
		whiteSpace: 'pre-wrap',
		'@media (max-width: 599px)': {
			fontSize: regularText.fontSize,
			lineHeight: regularText.lineHeight,
			marginBottom: '0.9em',
		},
	},
	fieldErrorContainer: {
		position: 'absolute',
		bottom: 0,
		transform: 'translateY(120%)',
		whiteSpace: 'nowrap',
	},
	fieldErrorSpan: {
		color: colors.errorRed,
		fontFamily: MetroSans.regular,
		fontSize: mobileTinyText.fontSize,
		lineHeight: mobileTinyText.lineHeight,
		marginLeft: '0.6666666666666666em',
	},
});
