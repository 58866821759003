import { Grid, makeStyles } from '@material-ui/core';
import { useRef, useEffect } from 'react';
import content from '../../../content/contact/feedback.json';
import { Router } from '../../../pages/contact';
import colors from '../../../styles/colors';
import { defaultSectionWidth } from '../../../styles/dimensions';
// @ts-ignore
import backgroundImage from '../../../images/contact/brain_with_airpods.jpg';

interface Props {
	router: Router;
}

const Feedback = (props: Props) => {
	const { router } = props;

	const classes = useClasses({ router });

	const sectionRef = useRef(null);

	useEffect(() => {
		if (router.step === 3) {
			sectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}, [router]);

	return (
		<section className={classes.section} ref={sectionRef}>
			<Grid container className={classes.grid}>
				<Grid className={classes.space} item md={7}></Grid>

				<Grid item md={5} className={classes.messageContainer}>
					<h2 className={classes.message} data-scroll data-scroll-speed="2">
						{content.feedback}
					</h2>
				</Grid>
			</Grid>
		</section>
	);
};

export default Feedback;

const useClasses = makeStyles({
	space: {
		'@media (max-width: 803px)': {
			display: 'none',
		},
	},
	section: (props: Props) => ({
		height: '70vw',
		minHeight: '400px',
		maxHeight: '75vh',
		backgroundImage: `url(${backgroundImage})`,
		backgroundPositionX: '20%',
		backgroundPositionY: '75%',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		display: props.router.step === 3 ? 'block' : 'none',
		'@media (max-width: 1231px)': {
			padding: '0 1em',
		},
	}),
	grid: {
		height: '100%',
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
	},
	messageContainer: {
		display: 'flex',
		justifyContent: 'center',
		'@media(min-width: 3000px)': {
			transform: 'translateX(5vw)',
		},
		'@media (min-width: 803px)': {
			alignItems: 'center',
		},
		'@media (max-width: 803px)': {
			padding: '0 10%',
			textAlign: 'center',
		},
	},
	message: {
		color: colors.white,
		'@media (max-width: 803px)': {
			fontSize: '2rem',
		},
		'@media (max-width: 660px)': {
			fontSize: '1.7rem',
		},
		'@media (max-width: 450px)': {
			fontSize: '1.3rem',
		},
	},
});
