import { FormControl, FormControlLabel, Grid, makeStyles, Radio, RadioGroup, Snackbar } from '@material-ui/core';
import { useState, useCallback } from 'react';
import content from '../../../content/contact/talkToUs.json';
import MetroSans from '../../../fonts/metro-sans/metroSans';
import RigrafExpanded from '../../../fonts/rigraf/expanded/rigrafExpanded';
import Rigraf from '../../../fonts/rigraf/regular/rigraf';
import colors from '../../../styles/colors';
import { defaultSectionWidth, regularText, smallText } from '../../../styles/dimensions';
import { Router, Branch } from '../../../pages/contact';
import { Link } from 'gatsby';
import { Alert } from '@material-ui/lab';
import { Control, Controller } from 'react-hook-form';
import MainButton from '../../../elements/mainButton';
import { StaticImage } from 'gatsby-plugin-image';

interface Props {
	router: Router;
	setProjectBranch: () => void;
	setOtherBranch: () => void;
	control: Control<Record<string, any>>;
	getValues: (value: string) => Branch;
}

interface ClassProps {
	router: Router;
}

const TalkToUs = (props: Props) => {
	const { router, setProjectBranch, setOtherBranch, control, getValues } = props;

	const [snackbarOpen, setSnackbarOpen] = useState(false);

	const classes = useClasses({ router });

	const handleButtonClick = useCallback(() => {
		const value = getValues('type');
		if (value === Branch.project) {
			setProjectBranch();
		} else if (value === Branch.other) {
			setOtherBranch();
		} else {
			setSnackbarOpen(true);
		}
	}, []);

	const handleSnackbarClose = useCallback(() => {
		setSnackbarOpen(false);
	}, []);

	return (
		<section className={classes.section}>
			<Grid container>
				<Grid item sm={2}></Grid>
				<Grid item xs={12} sm={8} data-scroll data-scroll-speed="2">
					<h2 className={classes.header}>{content.header.title}</h2>
					<p className={classes.description}>{content.header.description}</p>
					<p className={`${classes.description} ${classes.job}`}>
						{content.header.job}
						<Link
							to={content.header.jobApply.path}
							className={classes.link}
							aria-label="Go to careers page">
							{content.header.jobApply.text}
						</Link>
					</p>

					<form
						onSubmit={(event) => {
							event.preventDefault();
						}}>
						<FormControl component="fieldset" required>
							<Controller
								render={({ field }) => (
									<RadioGroup {...field} aria-label="contact type">
										{content.topics.map((topic, key) => (
											<FormControlLabel
												key={key}
												value={topic.value}
												label={topic.label}
												control={
													<Radio
														icon={
															<>
																<div className={classes.radioIcon}></div>
																<StaticImage
																	src="../../../images/contact/cd.png"
																	alt=""
																	className={classes.hoverIcon}
																	layout="constrained"
																	loading="eager"
																	placeholder="blurred"
																	aria-hidden="true"
																/>
															</>
														}
														checkedIcon={
															<StaticImage
																src="../../../images/contact/cd.png"
																alt=""
																className={classes.radioCheckedIcon}
																layout="constrained"
																loading="eager"
																placeholder="blurred"
																aria-hidden="true"
															/>
														}
													/>
												}
												className={classes.radio}
											/>
										))}
									</RadioGroup>
								)}
								control={control}
								defaultValue=""
								rules={{ required: true }}
								name="type"
							/>
						</FormControl>

						<div>
							<MainButton type="button" onClick={handleButtonClick} text={content.continue} />
						</div>
					</form>
				</Grid>
				<Grid item sm={2}></Grid>
			</Grid>
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={3000}
				onClose={handleSnackbarClose}
				classes={{ anchorOriginBottomCenter: classes.snackbarBottomCenter }}>
				<Alert
					onClose={handleSnackbarClose}
					severity="error"
					variant="filled"
					classes={{ message: classes.snackbarAlert }}>
					{content.errorMessage}
				</Alert>
			</Snackbar>
		</section>
	);
};

export default TalkToUs;

const useClasses = makeStyles({
	section: (props: ClassProps) => ({
		display: props.router.step === 0 ? 'block' : 'none',
		padding: '3.75em 0',
		paddingBottom: '7.625em',
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		'@media (max-width: 599px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
			paddingTop: '3.875em',
			paddingBottom: '5.25em',
		},
	}),
	radio: {
		margin: '1em 0',
		'& span:first-of-type': {
			padding: 0,
		},
		'&:hover $hoverIcon': {
			opacity: 0.5,
		},
		'& span:last-of-type': {
			fontFamily: RigrafExpanded.semiBold,
			fontSize: regularText.fontSize,
			lineHeight: regularText.lineHeight,
			position: 'relative',
			left: 'calc(8.33% * 12 / 8)',
			'@media (max-width: 599px)': {
				fontFamily: Rigraf.semiBold,
				fontSize: smallText.fontSize,
				lineHeight: smallText.lineHeight,
			},
		},
	},
	radioIcon: {
		width: '69px',
		height: '69px',
		borderRadius: '50%',
		border: '1px solid black',
	},
	radioCheckedIcon: {
		width: '71px',
		height: '71px',
	},
	hoverIcon: {
		// @ts-ignore
		position: 'absolute !important',
		opacity: 0,
		'&:hover': {
			opacity: 0.8,
		},
	},
	header: {
		marginTop: 0,
		marginBottom: '0.3em',
		'@media (max-width: 599px)': {
			fontSize: regularText.fontSize,
			lineHeight: regularText.lineHeight,
			marginBottom: '0.9em',
		},
	},
	description: {
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		color: colors.textGrey,
		margin: '0.25em 0',
		'@media (max-width: 599px)': {
			margin: '0.5em 0',
		},
	},
	job: {
		marginBottom: '0.5em',
		'@media (max-width: 599px)': {
			marginBottom: '1em',
		},
	},
	link: {
		borderBottom: `1px solid ${colors.textGrey}`,
	},
	snackbarAlert: {
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
	},
	snackbarBottomCenter: {
		'@media (min-width: 600px)': {
			bottom: '35%',
		},
	},
});
